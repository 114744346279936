import { tourOptions, tourSteps } from '@cuidardigital/commons/configs/tour'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import PagePatientDetail from '../../../../components/Tour/pagePatientDetail'
// tslint:disable-next-line: no-var-requires
const { ShepherdTour } = typeof window !== 'undefined' ? require('react-shepherd') : {}

export default (props: any) => {
	const data = useStaticQuery(query)

	return ShepherdTour ? (
		<ShepherdTour steps={tourSteps('bradesco')(data)} tourOptions={tourOptions}>
			<PagePatientDetail {...props} />
		</ShepherdTour>
	) : (
		''
	)
}

const query = graphql`
	query {
		saudeDigital: cosmicjsCuidarDigitalMarcas(slug: { eq: "bradesco" }) {
			metadata {
				tour_platform: tour_plataforma {
					button_next: botao_proximo
					button_back: botao_voltar
					patient_description: paciente_descricao

					schedules: agendamentos {
						title: titulo
						paragraph: paragrafo
					}

					new_appointment: novo_agendamento {
						new_appointment_title: titulo
						new_appointment_paragraph: paragrafo
					}

					acquire_certificate: adquirir_certificado {
						certificate_title: titulo
						certificate_paragraph: paragrafo
					}

					secretary: cadastrar_uma_secretarioa {
						secretary_title: titulo
						secretary_paragraph: paragrafo
					}

					doubts: duvidas {
						doubts_title: titulo
						doubts_paragraph: paragrafo
					}

					consultations: consultas_realizadas {
						consultations_title: titulo
						consultations_paragraph: paragrafo
					}

					medicalRecord: prontuario_eletronico {
						medicalRecord_title: titulo
						medicalRecord_paragraph: paragrafo
					}

					exams_results: resultados_de_exames {
						exams_title: titulo
						exams_paragraph: paragrafo
					}

					prescriptions: prescricoes_emitidas {
						prescriptions_title: titulo
						prescriptions_paragraph: paragrafo
					}
					tour_complete: tour_completo {
						tour_complete_title: titulo
						tour_complete_paragraph: paragrafo
						tour_complete_button_start: botao_comecar
					}
				}
			}
		}
	}
`
