import { navigate } from 'gatsby'
import pt from 'prop-types'
import React, { useContext, useEffect } from 'react'
import Header from '../../../components/Header'
import { HOME } from '../../../routes'
import { store } from '../../../stores/app'
import { initUserSession } from '../../../utils/session'
import ExamResults from '../ExamResults'

const PatientDetail = () => {
	const { state, dispatch } = useContext(store)
	initUserSession(state, dispatch)
	useEffect(() => {
		if (!state.patient.cpf) navigate(HOME)
	}, [])
	return (
		state.session.accessToken && (
			<>
				<ExamResults {...state.patient}/>
			</>
		)
	)
}

export default PatientDetail
